






























































































































































































































































































































































































































































































































































.tip {
  cursor: pointer;
  background: #f2f6fc;
}
/deep/.el-upload {
  border: none;
  height: 180px !important;
}
